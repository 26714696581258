import { ThemeConfig } from "antd/es/config-provider/context"

const colors = {
  primary: '#BBBBBB',
  secondary: '#B68400',
  accentButton: '#440412',
  secondaryButton: '#3D3935',
  text: '#000000'
}

const antdTheme: ThemeConfig = {
  token: {
    colorPrimary: colors.primary,
    borderRadius: 5,
    colorLink: colors.primary,
    colorLinkHover: colors.secondary,
    colorLinkActive: colors.secondary,
    fontFamily: 'MontserratMedium'
  },
  components: {
  }
}

export default antdTheme