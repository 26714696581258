import axios from "axios";
import { gnov } from "flowy-3-core";
import config from "../config";

/**
 * Search approved curp in the server
 *
 * @param {string} curp curp we're searching for
 * @returns
 */
async function searchCurp(curp: string) {
  const fid = "q15";
  // const formId = "648c9a2cc876ab001d18e64b";
  // const filter = `{{${fid}}} == "${curp}" AND ({{status}} != "s1" AND {{status}} != "s9")`;
  const formId = "65a7f35a02d68b001ee33095";
  // const filter = `{{${fid}}} == "${curp}" AND ({{status}} != "s1" AND {{status}} != "s9")`;
  const filter = `{{${fid}}} == "${curp}" AND ({{status}} == "s2" OR {{status}} == "s3" OR {{status}} == "s4")`;
  console.log('----------->filter: ', filter);
  const searchUrl = `${config.apiUrl}/main_api/v1/public/search/root_gizmo/data/${formId}`;

  try {
    const response = await axios({
      method: "post",
      url: searchUrl,
      data: {
        filter,
      },
    });

    if (response.status === 200) {
      const { filledForms } = response.data;

      if (filledForms.length > 0) {
        const rootGizmoDataFid = gnov(filledForms[0], `filledFormId`);
        const downloadUrl = `${config.apiUrl}/public/forms/${formId}/filled_forms/pdf/${rootGizmoDataFid}?pdfFolio=beneficiario_ct`;

        return downloadUrl;
      }
    }
  } catch (e) {
    console.log("eeeeeeeeeerror: ", e);
  }
}

export default searchCurp;
