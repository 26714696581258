import axios from "axios";
import { Subject } from "rxjs";
import { gnov } from "flowy-3-core";
import config from "../config";

class SearchBarController {
  _curp: string;
  _folio: string;

  result: Subject<any>;

  searching: Subject<boolean>;
  curp: Subject<string>;
  folio: Subject<string>;
  errorCurp: Subject<string>;
  errorFolio: Subject<string>;

  constructor() {
    this._curp = "";
    this._folio = "";

    this.result = new Subject<any>();

    this.searching = new Subject<boolean>();

    this.curp = new Subject<string>();
    this.folio = new Subject<string>();

    this.errorCurp = new Subject<string>();
    this.errorFolio = new Subject<string>();
  }

  setFolio(folio: string) {
    this._folio = folio;
    this.folio.next(folio);
  }

  _validateFolio(): boolean {
    if (this._folio === "") {
      this.errorFolio.next("Folio no puede estar vacío");
      return false;
    }

    this.errorFolio.next("");

    return true;
  }

  setCurp(curp: string) {
    this._curp = curp;
    this.curp.next(curp);
  }

  _validateCurp(): boolean {
    if (this._curp === "") {
      this.errorCurp.next("CURP no puede estar vacío");
      return false;
    }

    const regex = new RegExp(
      "^[A-Z]{1}[AEIOUX]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}"
    );
    const valid = regex.test(this._curp);

    if (!valid) this.errorCurp.next("CURP inválido");

    if (valid) this.errorCurp.next("");

    return valid;
  }

  async search() {
    const formId = "65a7f35a02d68b001ee33095";
    const curpFid = "q15";
    const folioFid = "q230";
    const validFolio = this._validateFolio();
    const validCurp = this._validateCurp();

    const filter = `{{${curpFid}}} == "${this._curp}" AND {{${folioFid}}} == "${this._folio}" AND ({{status}} != "s1" AND {{status}} != "s9")`;
    const searchUrl = `${config.apiUrl}/main_api/v1/public/search/root_gizmo/data/${formId}`;

    if (validFolio && validCurp) {
      try {
        this.searching.next(true);
        const response = await axios({
          method: "post",
          url: searchUrl,
          data: {
            filter,
          },
        });

        if (response.status === 200) {
          const { filledForms } = response.data;

          if (filledForms.length > 0) {
            const rootGizmoDataFid = gnov(filledForms[0], `filledFormId`);
            const downloadUrl = `${config.apiUrl}/public/forms/${formId}/filled_forms/pdf/${rootGizmoDataFid}?pdfFolio=beneficiario_ct`;

            this.result.next({
              downloadUrl,
              approved: true,
            });
          } else {
            this.result.next({
              approved: false,
            });
          }
        }
      } catch (e) {
      } finally {
        this.searching.next(false);
      }
    }
  }
}

export default SearchBarController;
